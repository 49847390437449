<template>
  <section>
    <land-section
      id="home_pound"
      space="20"
      color="grey lighten-4"
    >
      <v-container
        style="max-width: 900px;"
      >
        <v-row
          justify="center"
        >
          <v-col
            cols="12"
          >
            <span class="text-h4">{{ pdMeta.title }}</span>
          </v-col>
        </v-row>
        <v-row
          justify="center"
        >
          <v-col
            cols="12"
          >
            <content-card
              v-for="(item, index) in items"
              :key="`pd_${index}`"
              :item="item"
              :extended="extended"
            />

            <div
              v-if="items.length < 1"
              class="d-flex flex-row my-8"
            >
              <span class="co-text-md co-text-gray-500">
                没有本话题内容
              </span>
            </div>

            <div
              v-if="pagination.length"
              class="d-flex flex-row mt-6 mb-8"
            >
              <v-pagination
                v-model="pagination.current"
                :length="pagination.length"
                :total-visible="pagination.totalVisible"
                @input="changePagination($event)"
              />
            </div>
          </v-col>
        </v-row>
      </v-container>
    </land-section>
  </section>
</template>

<script>
  import api from '@/api/co.api'
  import app from '@/api/co.app'
  import lib from '@/api/co.lib'
  import web from '@/api/web/co.web'

  export default {
    components: {
      ContentCard: () => import('@/pages/sections/home/ContentCard.vue'),
    },
    data () {
      return {
        pdMeta: {},
        extended: false,
        params: null,
        items: [],
        pagination: {
          current: 1,
          length: 0,
          totalVisible: 7
        },
      }
    },
    created () {
      app.on(app.event.CONTENT_REMOVE, this.onRemove)
      app.on(app.event.POUND_REFRESH, this.onRefresh)

      this.pdMeta = JSON.parse(lib.cookie.get('Pound'))

      this.configParams()
      this.loadContents(true)
    },
    destroyed () {
      app.off(app.event.CONTENT_REMOVE)
      app.off(app.event.POUND_REFRESH)
    },
    methods: {
      onRefresh (ev) {
        if (!this.params) {
          return
        }

        this.pdMeta = Object.assign({}, ev)
        let talkTopic = ev.talkTopic || ''
        talkTopic = api.comm.trim(talkTopic.replace(/#/g, ''))

        this.params.data.talkTopic = talkTopic
        this.loadContents(true)
      },
      onRemove (ev) {
        const contentId = ev.contentId || ''
        let index = -1
        for (let i = 0; i < this.items.length; i++) {
          const item = this.items[i]
          if (item.contentId === contentId) {
            index = i
            break
          }
        }

        if (index !== -1) {
          this.items.splice(index, 1)
        }
      },
      changePagination (number) {
        const params = this.params
        const data = this.params.data
        const index = parseInt(number, 10) || 1

        this.pagination.current = index
        data.currentPage = index
        params.reload = true
        api.httpx.getItems(params)
      },
      configParams () {
        const me = this
        const executed = function (res) {
          // console.log('me.items: %o', me.items)
          const data = me.params.data
          const pagination = me.pagination
          const pageSize = parseInt(data.pageSize) || 12
          pagination.length = Math.ceil(res.total / pageSize)
        }

        let talkTopic = me.pdMeta.talkTopic || ''
        talkTopic = api.comm.trim(talkTopic.replace(/#/g, ''))

        me.params = web.content.getParams({
          sort: 'createTime',
          order: 'desc',
          talkTopic,
          state: web.comm.States.PUBLISH,
          caches: me.items,
          executed
        })
      },
      loadContents (reload = true) {
        this.params.reload = reload
        api.httpx.getItems(this.params)
      },
    }
  }
</script>
